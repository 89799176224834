
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { TechnicalClient, TicketClient } from '@/services/Services';
import TechnicianDetail from './technicianDetail.vue';
import * as VM from '@/viewModel';
import * as OM from '@/Model';

@Options({
    components: {
        TechnicianDetail
    }
})
export default class ListFilterSideBox extends Vue {

    @Prop({
        default: () => new VM.TicketsFilter()
    }) filters: VM.TicketsFilter;
    
    timeFilter: string = VM.TicketTimeFilter.Live;
    perPage: number = 10;
    pageNumber: number = 1;

    showScrollTopButton: boolean = false;
    showTechnicianScrollTopButton: boolean = false;
    loaded: boolean = false;

    tickets: OM.PaginatedResponseVmOfTicketVm = new OM.PaginatedResponseVmOfTicketVm();
    resultsList: OM.TicketVm[] = [];

    technicianDetails: OM.TechnicalDetailsVm = null;
    
    created() {
        this.initTickets(false);
    }

    mounted() {
        setTimeout(() => {
            var scrollSection = <HTMLElement>this.$refs.listFilterSideBox;
            scrollSection.addEventListener("scroll", () => {
                if(scrollSection.scrollTop > 500)
                    this.showScrollTopButton = true;
                else
                    this.showScrollTopButton = false;
            })
        }, 1500);
    }

    initTickets(addToOldList: boolean) {
        Promise.all([
            TicketClient.getTicketsByFilter(this.perPage, this.pageNumber, this.timeFilter, this.filters.startDate, 
                this.filters.endDate, this.filters.selectedPriority, this.filters.selectedRequestor, this.filters.selectedCanone, this.filters.selectedStatusList, this.filters.selectedTecnico)
        ])
        .then(xs => {
            this.tickets = xs[0];

            if(addToOldList)
                this.resultsList.push(...this.tickets.data);
            else {
                this.resultsList = this.tickets.data;
                this.technicianDetails = null;
            }

            setTimeout(() => {
                this.loaded = true;
            }, 500);
        })
    }

    get nextFilterName() {
        return VM.TicketTimeFilter.Next;
    }

    @Watch('timeFilter')
    @Watch('filters')
    updateValues() {
        this.initTickets(false);
    }

    moreData() {
        this.pageNumber = this.pageNumber + 1;
        this.initTickets(true);
    }

    scrollToTop() {
        var scrollSection = <HTMLElement>this.$refs.listFilterSideBox;
        scrollSection.scrollTop = 0;
    }

    selectTecnico(techUid: string) {
        TechnicalClient.getTechnicalDetails(techUid)
        .then(x => {
            this.technicianDetails = x;
        })
    }
    closeTecnicoDetailSection() {
        this.technicianDetails = null;
    }

}
