
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { TechnicalClient } from '@/services/Services';

@Options({
    components: {
    }
})
export default class MapFilters extends Vue {

    tecniciCollapse: boolean = false;
    buildingCollapse: boolean = false;

    tecniciRoles: string[] = [];
    tecniciRolesFilter: string[] = [];

    buildingActiveToggle: boolean = true;
    buildingNotActiveToggle: boolean = true;

    created() {
        TechnicalClient.getTechnicalTypeList()
        .then(x => {
            this.tecniciRoles = x;
            this.tecniciRoles.forEach(element => {
                this.tecniciRolesFilter.push(element);
            });
        })
    }

    @Watch('buildingActiveToggle')
    activeBuildingFilterUpdated() {
        this.$emit('activeBuildingFilterUpdated', this.buildingActiveToggle);
    }
    @Watch('buildingNotActiveToggle')
    notActiveBuildingFilterUpdated() {
        this.$emit('notActiveBuildingFilterUpdated', this.buildingNotActiveToggle);
    }

    toggleTech(value: any, role: string) {
        var existIndex = this.tecniciRolesFilter.indexOf(role);
        if(value && existIndex > -1)
            return;

        if(value && existIndex == -1)
            this.tecniciRolesFilter.push(role);
        else 
        {
            if(existIndex > -1)
                this.tecniciRolesFilter.splice(existIndex, 1);
        }

        this.$emit('tecniciRolesFilterUpdate', this.tecniciRolesFilter);
    }
    checkIfActive(role: string) {
        return this.tecniciRolesFilter.indexOf(role) > -1;
    }

}
