
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { TicketClient } from '@/services/Services';
import TechnicianDetailInfo from './technicianDetailInfo.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { TechnicalClient } from '@/services/Services';

@Options({
    components: {
        TechnicianDetailInfo
    }
})
export default class TechnicianDetail extends Vue {

    @Prop({
        default: () => new OM.TechnicalDetailsVm
    }) technicianDetail: OM.TechnicalDetailsVm;

    @Prop({
        default: null
    }) mapRoute: VM.MapRouteVM;

    technicianTimeFilter: string = VM.TicketTimeFilter.Live;
    technicianTickets: OM.TicketVm[] = [];

    showTechnicianScrollTopButton: boolean = false;

    googleMapsUrl: string = "";
    showGoogleMapsRouteSent: boolean = false;

    created() {
        this.updateTicketsOfTechnician();
    }

    @Watch('technicianTimeFilter')
    updateTicketsOfTechnician() {
        var proms = [];

        if(this.technicianTimeFilter == VM.TicketTimeFilter.Previous)
            proms.push(TicketClient.getPreviousTicketsWithTechnical(this.technicianDetail.cfId));
        else if(this.technicianTimeFilter == VM.TicketTimeFilter.Live)
            proms.push(TicketClient.getLiveTicketsWithTechnical(this.technicianDetail.cfId));
        else
            proms.push(TicketClient.getNextTicketsWithTechnical(this.technicianDetail.cfId));

        Promise.all(proms)
        .then(xs => {
            this.technicianTickets = xs[0];
            setTimeout(() => {
                var scrollSection = <HTMLElement>this.$refs.tecnicoDetailContainer;
                scrollSection.addEventListener("scroll", () => {
                    if(scrollSection.scrollTop > 500)
                        this.showTechnicianScrollTopButton = true;
                    else
                        this.showTechnicianScrollTopButton = false;
                })
            }, 500);
        })
    }

    scrollToTechnicianTop() {
        var scrollSection = <HTMLElement>this.$refs.tecnicoDetailContainer;
        scrollSection.scrollTop = 0;
    }

    sendPath() {
        const startLat = this.mapRoute.startPointLatitude;
        const startLng = this.mapRoute.startPointLongitude;
        const endLat = this.mapRoute.destinationLatitude;
        const endLng = this.mapRoute.destinationLongitude;
        const travelMode = 'driving';

        this.googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${startLat},${startLng}&destination=${endLat},${endLng}&travelmode=${travelMode}`;

        TechnicalClient.sendMapsRouteToTechnicianEmail(this.technicianDetail.email, this.googleMapsUrl)
        .then(x => {
            this.showGoogleMapsRouteSent = true;
        })
    }

}
