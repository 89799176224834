import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "timeFiltersContainer" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (value, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["tab", {'active': _ctx.timeFilter == value}]),
        onClick: ($event: any) => (_ctx.timeFilter=value),
        key: index
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: value,
          text: value
        }, null, 8, ["localizedKey", "text"])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}