import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/apprendista.svg'
import _imports_1 from '@/assets/img/apprendista_white.svg'
import _imports_2 from '@/assets/img/giardiniere.svg'
import _imports_3 from '@/assets/img/giardiniere_white.svg'
import _imports_4 from '@/assets/img/meccanico.svg'
import _imports_5 from '@/assets/img/meccanico_white.svg'
import _imports_6 from '@/assets/img/polivalente.svg'
import _imports_7 from '@/assets/img/polivalente_white.svg'
import _imports_8 from '@/assets/img/strutturali.svg'
import _imports_9 from '@/assets/img/strutturali_white.svg'
import _imports_10 from '@/assets/img/antincendio.svg'
import _imports_11 from '@/assets/img/antincendio_white.svg'
import _imports_12 from '@/assets/img/ascensorista.svg'
import _imports_13 from '@/assets/img/ascensorista_white.svg'
import _imports_14 from '@/assets/img/cleaner.svg'
import _imports_15 from '@/assets/img/cleaner_white.svg'
import _imports_16 from '@/assets/img/edile.svg'
import _imports_17 from '@/assets/img/edile_white.svg'
import _imports_18 from '@/assets/img/elettricista.svg'
import _imports_19 from '@/assets/img/elettricista_white.svg'
import _imports_20 from '@/assets/img/fabbro.svg'
import _imports_21 from '@/assets/img/fabbro_white.svg'
import _imports_22 from '@/assets/img/fervo.svg'
import _imports_23 from '@/assets/img/fervo_white.svg'
import _imports_24 from '@/assets/img/frigorista.svg'
import _imports_25 from '@/assets/img/frigorista_white.svg'
import _imports_26 from '@/assets/img/tecnico.png'


const _hoisted_1 = {
  key: 0,
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1
}
const _hoisted_3 = {
  key: 2,
  src: _imports_2
}
const _hoisted_4 = {
  key: 3,
  src: _imports_3
}
const _hoisted_5 = {
  key: 4,
  src: _imports_4
}
const _hoisted_6 = {
  key: 5,
  src: _imports_5
}
const _hoisted_7 = {
  key: 6,
  src: _imports_6
}
const _hoisted_8 = {
  key: 7,
  src: _imports_7
}
const _hoisted_9 = {
  key: 8,
  src: _imports_8
}
const _hoisted_10 = {
  key: 9,
  src: _imports_9
}
const _hoisted_11 = {
  key: 10,
  src: _imports_10
}
const _hoisted_12 = {
  key: 11,
  src: _imports_11
}
const _hoisted_13 = {
  key: 12,
  src: _imports_12
}
const _hoisted_14 = {
  key: 13,
  src: _imports_13
}
const _hoisted_15 = {
  key: 14,
  src: _imports_14
}
const _hoisted_16 = {
  key: 15,
  src: _imports_15
}
const _hoisted_17 = {
  key: 16,
  src: _imports_16
}
const _hoisted_18 = {
  key: 17,
  src: _imports_17
}
const _hoisted_19 = {
  key: 18,
  src: _imports_18
}
const _hoisted_20 = {
  key: 19,
  src: _imports_19
}
const _hoisted_21 = {
  key: 20,
  src: _imports_20
}
const _hoisted_22 = {
  key: 21,
  src: _imports_21
}
const _hoisted_23 = {
  key: 22,
  src: _imports_22
}
const _hoisted_24 = {
  key: 23,
  src: _imports_23
}
const _hoisted_25 = {
  key: 24,
  src: _imports_24
}
const _hoisted_26 = {
  key: 25,
  src: _imports_25
}
const _hoisted_27 = {
  key: 26,
  src: _imports_26
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.localRole == 'apprendista' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'apprendista' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'giardiniere' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'giardiniere' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'meccanico' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'meccanico' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_6))
      : _createCommentVNode("", true),
    ((_ctx.localRole == 'polivalente' || _ctx.localRole == '') && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_7))
      : _createCommentVNode("", true),
    ((_ctx.localRole == 'polivalente' || _ctx.localRole == '') && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_8))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'strutturali' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_9))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'strutturali' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_10))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'antincendio' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_11))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'antincendio' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_12))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'ascensorista' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_13))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'ascensorista' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_14))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'cleaner' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_15))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'cleaner' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_16))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'edile' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_17))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'edile' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_18))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'elettricista' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_19))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'elettricista' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_20))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'fabbro' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_21))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'fabbro' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_22))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'fervo' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_23))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'fervo' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_24))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'frigorista' && !_ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_25))
      : _createCommentVNode("", true),
    (_ctx.localRole == 'frigorista' && _ctx.isActive)
      ? (_openBlock(), _createElementBlock("img", _hoisted_26))
      : _createCommentVNode("", true),
    (!_ctx.localRole)
      ? (_openBlock(), _createElementBlock("img", _hoisted_27))
      : _createCommentVNode("", true)
  ], 64))
}