
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BuildClient, VehicleClient } from '@/services/Services';
import GoogleAutocompleteGetResults from '@/components/googleAutocompleteGetResults.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        GoogleAutocompleteGetResults
    }
})
export default class SearchModal extends Vue {

    resultsList: any[] = [];

    @Prop({
        default: false
    }) anywhere: boolean;

    @Prop({
        type: [Function]
    }) callbackTechnician: any;
    @Prop({
        type: [Function]
    }) callbackBuilding: any;
    @Prop({
        type: [Function]
    }) callbackAddress: any;

    searchText: string = "";
    selectedAddress = new VM.GeoPointVm();
    geocoder: any;

    techniciansVehiclesResults: OM.VehicleWithDriverVm[] = [];
    buildingsResults: OM.BuildVm[] = [];

    created() {
        this.geocoder = new google.maps.Geocoder();
    }

    closeModal() {
        this.$opModal.closeLast();
    }

    searchCityLocation(address: any){
        this.selectedAddress.name = address.description;

        var request = {
            placeId: address.place_id
        }
        this.geocoder.geocode(request, this.setLatitudeLognitude);
    }

    setLatitudeLognitude(data, text){
        this.selectedAddress.latitude = data[0].geometry.location.lat();
        this.selectedAddress.longitude = data[0].geometry.location.lng();

        this.callbackAddress(this.selectedAddress);
    }

    changeAddress(data){
        this.resultsList = data;
    }

    timeoutHandler;
    emitText(text: string){
        clearTimeout(this.timeoutHandler);
        this.searchText = text;

        if(this.searchText.length < 3)
            return;

        this.timeoutHandler = setTimeout(() => {
            var searchValue = (<any>this.searchText).target.value;
            Promise.all([
                VehicleClient.getFilteredVehicleList(searchValue),
                BuildClient.getFilteredBuildingList(searchValue)
            ])
            .then(xs => {
                this.techniciansVehiclesResults = xs[0];
                this.buildingsResults = xs[1];
            })
        }, 700);
    }

}
