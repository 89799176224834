
import { TechnicalClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class EditPhoneNumber extends Vue {

    @Prop() technicianUid: string;
    @Prop() technicianRole: string;
    @Prop() technicianFullName: string;
    @Prop() technicianPhoneNumber: string;

    @Prop() callback: any;

    localPhoneNumber: string = "";

    created() {
        this.localPhoneNumber = this.technicianPhoneNumber;
    }

    save() {
        TechnicalClient.updatePhoneNumber(this.technicianUid, this.localPhoneNumber)
        .then(x => {
            this.callback(this.localPhoneNumber);
        })
    }

}
