
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class TechniciansImages extends Vue {

    @Prop({
        default: ""
    }) role: string;
    localRole: string = "";

    @Prop({
        default: false
    }) isActive: boolean;

    created() {
        this.localRole = this.role.toLowerCase();
    }

}
