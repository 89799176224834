import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "formsContainer" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { class: "d-flex flex-wrap" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "d-flex align-items-center mt-4 pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_form_field, {
        type: "date",
        modelValue: _ctx.startDate,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event)),
        placeholderKey: "Data di inizio:",
        placeholderValue: "Data di inizio:",
        labelKey: "Data di inizio:",
        labelValue: "Data di inizio:"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_field, {
        type: "date",
        modelValue: _ctx.endDate,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event)),
        placeholderKey: "Data di fine:",
        placeholderValue: "Data di fine:",
        labelKey: "Data di fine:",
        labelValue: "Data di fine:"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_field, {
        type: "select-string",
        modelValue: _ctx.selectedPriority,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedPriority) = $event)),
        list: _ctx.priorities,
        placeholderKey: "Priorità:",
        placeholderValue: "Priorità:",
        labelKey: "Priorità:",
        labelValue: "Priorità:"
      }, null, 8, ["modelValue", "list"]),
      _createVNode(_component_form_field, {
        type: "select-string",
        modelValue: _ctx.selectedRequestor,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedRequestor) = $event)),
        list: _ctx.requestors,
        placeholderKey: "Requestor:",
        placeholderValue: "Requestor:",
        labelKey: "Requestor:",
        labelValue: "Requestor:"
      }, null, 8, ["modelValue", "list"]),
      _createVNode(_component_form_field, {
        type: "select-string",
        modelValue: _ctx.selectedCanoneTypeString,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedCanoneTypeString) = $event)),
        list: _ctx.canoniTypeList,
        placeholderKey: "Canone:",
        placeholderValue: "Canone:",
        labelKey: "Canone:",
        labelValue: "Canone:"
      }, null, 8, ["modelValue", "list"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, [
          _createVNode(_component_localized_text, {
            localizedKey: "Status:",
            text: "Status:"
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticketStatusList, (status, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["labelAsCheck", {'active' : _ctx.isStatusActive(status.status)}]),
              key: index,
              onClick: ($event: any) => (_ctx.toggleStatus(status.status))
            }, _toDisplayString(status.description), 11, _hoisted_5))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: "button btn-secondary w-50 me-1",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clearFilters()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Pulisci filtri",
          text: "Pulisci filtri"
        })
      ]),
      _createElementVNode("button", {
        class: "button btn-primary w-50 ms-1",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.applyFilters()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Filtra",
          text: "Filtra"
        })
      ])
    ])
  ], 64))
}