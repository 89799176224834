export default {

    removeSpaces(text: string) : string {
        if(!text)
            return text;

        var temp = text.trim();

        while(temp.indexOf("  ") != -1)
            temp = temp.replace("  ", " ").trim();

        return temp;
    },

    getUniversalISOStringDate(input: Date): any {
        var data = new Date(input);
        data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

        return data.toISOString();
    },

    getUniversalDate(input: any): Date {
        var data = new Date(input);
        data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

        return data;
    },

}