
import { Options, Vue } from 'vue-class-component';
import { TicketClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
    }
})
export default class ListFilter extends Vue {
    
    startDate: string = "";
    endDate: string = "";
    selectedPriority: string = "";
    priorities: string[] = [];
    selectedRequestor: string = "";
    requestors: string[] = [];
    selectedTecnico: string = "";
    tecnici: string[] = [];

    selectedTicketStatusList: OM.TicketStatus[] = [];
    selectedCanoneTypeString: string = "";
    canoniTypeList: string[] = [];

    @Prop({
        default: []
    }) ticketStatusList: OM.TicketStatusVM[];
    
    created() {
        Object.keys(OM.CanoneType).forEach(key => {
            var numb = parseInt(key);

            if(isNaN(numb)) {
                this.selectedCanoneTypeString = this.selectedCanoneTypeString ? this.selectedCanoneTypeString : key;
                this.canoniTypeList.push(key);
            }
        });

        this.initTickets();
    }

    initTickets() {
        Promise.all([
            TicketClient.getPriorityList(),
            TicketClient.getRequestorList(),
            TicketClient.getTechniciansList()
        ])
        .then(xs => {
            this.priorities = xs[0];
            this.requestors = xs[1];
            this.tecnici = xs[2];

            this.initializeSelectedStatusList();
        })
    }

    initializeSelectedStatusList() {
        this.ticketStatusList.forEach(element => {
            if(element.status != OM.TicketStatus.Clo && element.status != OM.TicketStatus.Com)
                this.selectedTicketStatusList.push(element.status);
        });
    }

    toggleStatus(status: OM.TicketStatus) {
        var findIndex = this.selectedTicketStatusList.indexOf(status);

        if(findIndex == -1)
            this.selectedTicketStatusList.push(status);
        else
            this.selectedTicketStatusList.splice(findIndex, 1);
    }
    isStatusActive(status: OM.TicketStatus) {
        return this.selectedTicketStatusList.indexOf(status) > -1;
    }

    get selectedCanone() {
        var ris = OM.CanoneType.ALL;

        Object.keys(OM.CanoneType).forEach(key => {
            if(this.selectedCanoneTypeString == OM.CanoneType[key])
                ris = OM.CanoneType[key];
        });

        return ris;
    }

    clearFilters() {
        this.startDate = "";
        this.endDate = "";
        this.selectedPriority = "";
        this.selectedRequestor = "";
        this.selectedTecnico = "";
        this.selectedCanoneTypeString = "";
        this.initializeSelectedStatusList();

        Object.keys(OM.CanoneType).forEach(key => {
            var numb = parseInt(key);
            if(isNaN(numb))
                this.selectedCanoneTypeString = this.selectedCanoneTypeString ? this.selectedCanoneTypeString : key;
        });

        this.$emit('clearFilters');
    }
    applyFilters() {
        var filters: VM.TicketsFilter = new VM.TicketsFilter();
        filters.startDate = this.startDate;
        filters.endDate = this.endDate;
        filters.selectedPriority = this.selectedPriority;
        filters.selectedRequestor = this.selectedRequestor;
        filters.selectedTecnico = this.selectedTecnico;
        filters.selectedStatusList = this.selectedTicketStatusList;
        filters.selectedCanone = this.selectedCanone;

        this.$emit('applyFilters', filters);
    }

}
