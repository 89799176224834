
import { BuildClient, TechnicalClient, TicketClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import TimeFilterContainer from './timeFilterContainer.vue';
import TicketCard from './ticketCard.vue';
import TechnicianDetailInfo from './technicianDetailInfo.vue';
import TechniciansImages from './techniciansImages.vue';
import CopyButton from './copyButton.vue';
import * as OM from '@/Model';

@Options({
    components: {
        TimeFilterContainer,
        TicketCard,
        TechnicianDetailInfo,
        TechniciansImages,
        CopyButton
    }
})
export default class MarkerDetail extends Vue {

    @Prop({
        default: ""
    }) bl_id: string;

    @Prop({
        default: 0
    }) lat: number;
    @Prop({
        default: 0
    }) lng: number;

    model: OM.BuildDetailsVm = new OM.BuildDetailsVm();
    liveTickets: OM.TicketVm[] = [];
    previousTickets: OM.TicketVm[] = [];
    nextTickets: OM.TicketVm[] = [];

    timeFilter: string = "Live";
    step: number = 1;

    radiusKm: number = 10;
    techniciansTypeFilters: string[] = [];
    techniciansTypeList: string[] = [];
    technicians: OM.VehicleWithDistanceFromBuildVm[] = [];
    filteredTechnicians: OM.VehicleWithDistanceFromBuildVm[] = [];

    technicianDetails: OM.TechnicalDetailsVm = new OM.TechnicalDetailsVm();
    selectedPosition: OM.VehicleWithDistanceFromBuildVm = null;
    googleMapsUrl: string = "";
    showGoogleMapsRouteSent: boolean = false;

    created() {
        Promise.all([
            TechnicalClient.getTechnicalTypeList(),
            BuildClient.getBuildDetails(this.bl_id),
            TicketClient.getLiveTicketsWithBuild(this.bl_id),
            TicketClient.getPreviousTicketsWithBuild(this.bl_id),
            TicketClient.getNextTicketsWithBuild(this.bl_id)
        ])
        .then(xs => {
            this.techniciansTypeList = xs[0];
            this.model = xs[1];
            this.liveTickets = xs[2];
            this.previousTickets = xs[3];
            this.nextTickets = xs[4];
        })
    }

    mounted() {
        var bigScroll = <HTMLElement>this.$refs.markerDetailContainer;
        var header = <HTMLElement>this.$refs.header;
        var cta = <HTMLElement>this.$refs.searchCTA;

        bigScroll.addEventListener("scroll", (event) => {
            if(bigScroll.scrollTop > 40)
                header.classList.add("fixedHeader");
            else
                header.classList.remove("fixedHeader");

            if(bigScroll.scrollTop > 200)
                cta.classList.add("fixedCTA");
            else
                cta.classList.remove("fixedCTA");
        })
    }

    get fullAddress() {
        //+ this.model.cap + " "
        return this.model.address + ", " + this.model.city + " " + this.model.country + " (" + this.model.region + ")";
    }

    searchForTechnicians() {
        BuildClient.getVehiclesFromBuild(this.lat, this.lng, this.radiusKm)
        .then(x => {
            this.technicians = x;
            this.step = 2;

            this.filteredTechnicians = x;
            if(this.techniciansTypeFilters.length > 0)
                this.filteredTechnicians = this.technicians.filter(x => this.techniciansTypeFilters.indexOf(x.role) > -1);
        })
    }

    toggleFilterType(role: string) {
        var existIndex = this.techniciansTypeFilters.indexOf(role);
        if(existIndex == -1)
            this.techniciansTypeFilters.push(role);
        else
            this.techniciansTypeFilters.splice(existIndex, 1);
    }
    checkIfActive(role: string) {
        return this.techniciansTypeFilters.indexOf(role) > -1;
    }
    getFiltersLength(role: string) {
        return this.technicians.filter(x => x.role == role).length;
    }

    @Watch('radiusKm')
    @Watch('techniciansTypeFilters.length')
    updateQuery() {
        this.searchForTechnicians();
    }

    selectTechnician(tech: OM.VehicleWithDistanceFromBuildVm) {
        this.$emit('technicianSelected', tech);
        TechnicalClient.getTechnicalDetails(tech.uId)
        .then(x => {
            this.technicianDetails = x;
            this.selectedPosition = tech;
            this.step = 3;
        })
    }

    sendPath() {
        const startLat = this.selectedPosition.coordinate.coordinate.latitude;
        const startLng = this.selectedPosition.coordinate.coordinate.longitude;
        const endLat = this.lat;
        const endLng = this.lng;
        const travelMode = 'driving';

        this.googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${startLat},${startLng}&destination=${endLat},${endLng}&travelmode=${travelMode}`;

        TechnicalClient.sendMapsRouteToTechnicianEmail(this.technicianDetails.email, this.googleMapsUrl)
        .then(x => {
            this.showGoogleMapsRouteSent = true;
        })
    }

}
