
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EditPhoneNumber from './modals/editPhoneNumber.vue';
import * as OM from '@/Model';

@Options({})
export default class TechnicianDetailInfo extends Vue {

    @Prop({
        default: () => new OM.TechnicalDetailsVm
    }) technicianDetail: OM.TechnicalDetailsVm;

    created() {
    }

    editPhoneNumber() {
        this.$opModal.show(EditPhoneNumber, {
            technicianUid: this.technicianDetail.uId,
            technicianRole: this.technicianDetail.role,
            technicianFullName: this.technicianDetail.name + " " + this.technicianDetail.surname,
            technicianPhoneNumber: this.technicianDetail.phone,
            callback: (phone: string) => {
                this.technicianDetail.phone = phone;
                this.$opModal.closeLast();
            }
        })
    }

}
