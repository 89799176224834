import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import begin from '@/views/begin';
import map from '@/views/map';
import users from '@/views/users';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
    ...begin.routes(),
    {
        path: '/',
        component: () => import("../views/layout.vue"),
        beforeEnter: (to, from) => {
            // if(!store.state.loggedUser.identifier)
            //     return false
        },
        children: [
            //backoffice
            ...map.routes()
        ]
    },
    ...users.routes()
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
