import * as OM from '@/Model';
import store from '@/store';
import { CommonServices } from './CommonServices';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }

    getLoggedUser() {
        var loggedUser = this.readFromLocalStorage<OM.LoggedUserVM>('loggedUser');

        if(!loggedUser){
            loggedUser = new OM.LoggedUserVM();
            loggedUser.preferredCulture = "it-IT";
            StorageServices.setLoggedUser(loggedUser);
        }

        return loggedUser;
    }
    
    setLoggedUser(response: OM.LoggedUserVM) {
        window.localStorage.setItem('loggedUser', JSON.stringify(response));
    }

    setLoginToken(token: string) {
        return CommonServices.setAuthToken(token);
    }
    getLoginToken(): string {
        return window.localStorage.getItem('authtoken');
    }

    setLoginRefreshToken(token: string) {
        return window.localStorage.setItem('refreshtoken', token);
    }
    getLoginRefreshToken(): string {
        return window.localStorage.getItem('refreshtoken');
    }

    setNewTokens(token: string, refreshToken: string){
        StorageServices.setLoginToken(token);
        StorageServices.setLoginRefreshToken(refreshToken);

        var loggedUser = store.state.loggedUser;
        loggedUser.token = token;
        loggedUser.refreshToken = refreshToken;

        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }
    
}

export let StorageServices = new _StorageServices();