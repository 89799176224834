import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/copy.svg'


const _hoisted_1 = { class: "copyTextContainer" }
const _hoisted_2 = { class: "textContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCopiedText)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "opacity",
          class: "duration04 no_leave_animation",
          appear: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_localized_text, {
                localizedKey: "Copied to clipboard!",
                text: "Copied to clipboard!"
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      src: _imports_0,
      class: "pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyCTA()))
    })
  ]))
}