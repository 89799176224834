import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "rangeContainer" }
const _hoisted_2 = { class: "d-flex justify-content-between mb-2" }
const _hoisted_3 = { class: "fs_12 fw_500 marine ms-1" }
const _hoisted_4 = ["readonly", "tabindex", "min", "max", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (value) => {
        return (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(value), 1))
      }), 256))
    ]),
    _withDirectives(_createElementVNode("input", {
      ref: "range",
      type: "range",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      style: _normalizeStyle('background-size:' + (_ctx.localValue - _ctx.min) * 100 / (_ctx.max - _ctx.min) + '% 100%'),
      readonly: _ctx.readonly,
      tabindex: _ctx.tabIndex,
      min: _ctx.min,
      max: _ctx.max,
      step: _ctx.step
    }, null, 12, _hoisted_4), [
      [_vModelText, _ctx.localValue]
    ])
  ]))
}