
import { Options, Vue } from 'vue-class-component';
import { LoginServices } from '@/services/LoginServices';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/Model';

@Options({})
export default class AccountBox extends Vue {

    optionsMenuOpened: boolean = false;
    loggedUser: OM.LoggedUserVM = new OM.LoggedUserVM();

    created() {
        this.loggedUser = StorageServices.getLoggedUser();
    }

    toggleMenu() {
        if(!this.isAdmin) {
            this.logout();
            return;
        }

        this.optionsMenuOpened = !this.optionsMenuOpened;
        this.$emit('menuSettingChanged', this.optionsMenuOpened);
    }

    get isAdmin() {
        return this.loggedUser.role == 'admin';
    }

    logout() {
        LoginServices.logout();
    }

}
