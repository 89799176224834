import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1f130d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = ["tabindex", "readonly"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      tabindex: _ctx.tabIndex,
      readonly: _ctx.readonly
    }, [
      (_ctx.placeholderKey && _ctx.placeholderValue)
        ? (_openBlock(), _createElementBlock("option", {
            key: 0,
            hidden: "",
            disabled: "",
            value: _ctx.getDefaultValue
          }, _toDisplayString(_ctx.$localizationService.getLocalizedValue(_ctx.placeholderKey, _ctx.placeholderValue)), 9, _hoisted_3))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localList, (item, i) => {
        return (_openBlock(), _createElementBlock("option", {
          key: i,
          value: item
        }, _toDisplayString(item), 9, _hoisted_4))
      }), 128))
    ], 8, _hoisted_2), [
      [_vModelSelect, _ctx.localValue]
    ])
  ]))
}