
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import * as VM from '@/viewModel';

@Options({})
export default class TimeFilterContainer extends Vue {

    timeFilter: string = VM.TicketTimeFilter.Live;

    created() {}

    get values() {
        var ris: string[] = [];

        Object.keys(VM.TicketTimeFilter).forEach(key => {
            ris.push(VM.TicketTimeFilter[key]);
        });

        return ris;
    }

    @Watch('timeFilter')
    updateValueOutside() {
        this.$emit('changed', this.timeFilter);
    }

}
