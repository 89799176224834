
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({})
export default class TicketCard extends Vue {

    @Prop({
        default: () => new OM.TicketVm
    }) ticket: OM.TicketVm;

    @Prop({
        default: false
    }) hideDate: boolean;

    detailsOpened: boolean = false;
    ticketSelected: boolean = false;
    selectedTechnicianId: string = "";

    created() {
    }

    selectTechnician(tech: OM.TechnicalVm) {
        this.ticketSelected = true;
        this.selectedTechnicianId = tech.uId;
        this.$emit('technicianSelected', tech.uId);
    }

}
