
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import WithModel from '../mixins/withModel'

@Options({
    components: {
    }
})
export default class inputRange extends mixins(WithModel) {

    @Prop({
        default: 0
    }) tabIndex;

    @Prop({
        default: false
    }) readonly: boolean;

    @Prop({
        default: 0
    }) min: number;

    @Prop({
        default: 10
    }) max: number;

    @Prop({
        default: 1
    }) step: number;

    values: string[] = [];
    
    mounted(){
        for(var i = this.min; i <= this.max; i ++) {
            if(i % this.step == 0)
                this.values.push(i.toString() + " km");
        }
    }

}
