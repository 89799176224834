
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { TicketClient } from '@/services/Services';
import AccountBox from './accountBox.vue';
import MapFilters from './mapFilters.vue';
import ListFilters from './listFilters.vue';
import ListResults from './listResults.vue';
import TimeFilterContainer from './timeFilterContainer.vue';
import SearchModal from './modals/searchModal.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        AccountBox,
        MapFilters,
        ListFilters,
        ListResults,
        TimeFilterContainer
    }
})
export default class FiltersBox extends Vue {

    @Prop({
        default: true
    }) showFiltersSection: boolean;
    localShowFiltersSection: boolean = true;

    menuOpened: boolean = false;

    activeFilter: string = "map";

    placeFilter: string = "";
    listFilter: VM.TicketsFilter = new VM.TicketsFilter();
    ticketStatusList: OM.TicketStatusVM[] = [];

    created() {
        TicketClient.getAllTicketStatus()
        .then(x => {
            this.ticketStatusList = x;

            x.forEach(element => {
                if(element.status != OM.TicketStatus.Clo && element.status != OM.TicketStatus.Com)
                    this.listFilter.selectedStatusList.push(element.status);
            });

            this.updateShowFiltersSection();
        })
    }

    @Watch('showFiltersSection')
    updateShowFiltersSection() {
        this.localShowFiltersSection = this.showFiltersSection;
    }

    goToMap() {
        this.activeFilter = 'map';
    }

    clearFilters() {
        this.listFilter = new VM.TicketsFilter();
    }
    applyFilters(filters: VM.TicketsFilter) {
        this.listFilter = filters;
    }

    openSearchModal() {
        this.$opModal.show(SearchModal, {
            callbackTechnician: (selectedTechnician: OM.VehicleWithDriverVm) => {
                this.$opModal.closeLast();
                this.$emit('openSelectedTechnician', selectedTechnician);
            },
            callbackBuilding: (selectedBuilding: OM.BuildVm) => {
                this.$opModal.closeLast();
                this.$emit('openSelectedBuilding', selectedBuilding);
            },
            callbackAddress: (selectedAddress: VM.GeoPointVm) => {
                this.$opModal.closeLast();
                this.$emit('showSelectedAddress', selectedAddress);
            }
        })
    }

}
