
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class CopyButton extends Vue {

    @Prop({
        default: ""
    }) text: string;

    showCopiedText: boolean = false;

    created() {
    }

    copyCTA() {
        navigator.clipboard.writeText(this.text);

        this.showCopiedText = true;
        setTimeout(() => {
            this.showCopiedText = false;
        }, 1000);
    }

}
