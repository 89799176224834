import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal boxContainer d-block" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "d-block mt-1 fw_700 fs_18" }
const _hoisted_4 = { class: "mt-4 pt-1" }
const _hoisted_5 = { class: "d-flex w-100 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_technicians_images = _resolveComponent("technicians-images")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_technicians_images, {
        role: _ctx.technicianRole,
        class: "icona"
      }, null, 8, ["role"]),
      (_ctx.technicianRole)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            localizedKey: _ctx.technicianRole.toLowerCase(),
            text: _ctx.technicianRole.toLowerCase(),
            class: "text-capitalize fs_12 fw_500 marine ms-1"
          }, null, 8, ["localizedKey", "text"]))
        : (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            localizedKey: "Polivalente",
            text: "Polivalente",
            class: "text-capitalize fs_12 fw_500 marine ms-1"
          }))
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.technicianFullName), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_form_field, {
        type: "text",
        modelValue: _ctx.localPhoneNumber,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localPhoneNumber) = $event)),
        placeholderKey: "Numero di telefono",
        placeholderValue: "Numero di telefono",
        labelKey: "Numero di telefono",
        labelValue: "Numero di telefono"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "btn btn-secondary text-center justify-content-center w-50 me-1",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Annulla",
          text: "Annulla"
        })
      ]),
      _createElementVNode("button", {
        class: "btn btn-primary text-center justify-content-center w-50 ms-1",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.save()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Salva",
          text: "Salva"
        })
      ])
    ])
  ]))
}